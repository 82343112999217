import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth-guard.guard';
import { PermisionGuard } from './core/permision-guard.guard';
import { IsloggedGuard } from './core/islogged-guard.guard';

const routes: Routes = [
  //Little Component

  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    canActivate: [IsloggedGuard],
    loadChildren: () =>
      import('./pages/auth/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, PermisionGuard],
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'loads',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/load/load.module').then((m) => m.LoadsModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/reports/report.module').then((m) => m.ReportsModule),
  },
  {
    path: 'notaccess',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/not-access/not-access.module').then(
        (m) => m.NotAccessModule
      ),
  },
  {
    path: 'digal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/digal/digal.module').then((m) => m.DigalModule),
  },
  {
    path: 'order',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/order/order.module').then((m) => m.MadisonModule),
  },
  {
    path: 'contract-management',
    canActivate: [AuthGuard, PermisionGuard],
    loadChildren: () =>
      import('./pages/contract-management/history-contract.module').then(
        (m) => m.HistoryContractModule
      ),
  },
  {
    path: 'attention-service',
    canActivate: [AuthGuard, PermisionGuard],
    loadChildren: () =>
      import('./pages/attention-service/request.module').then(
        (m) => m.RequestModule
      ),
  },
  {
    path: 'crm',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/crm/crm.module').then(
        (m) => m.CRMModule
      ),
  },
  {
    path: 'item',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/item/item.module').then(
        (m) => m.ItemModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
