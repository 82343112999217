import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '@app/shared/models/response.interface';
import { User } from '@app/shared/models/user.interface';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  private userName = new BehaviorSubject('');
  userName$ = this.userName.asObservable();

  private userEmail = new BehaviorSubject('');
  userEmail$ = this.userEmail.asObservable();

  private userClientName = new BehaviorSubject('');
  userClientName$ = this.userClientName.asObservable();

  private userCompanyId = new BehaviorSubject('');
  userCompanyId$ = this.userCompanyId.asObservable();

  changeData(
    userName: string,
    userEmail: string,
    clientName: string,
    clientId: string
  ) {
    this.userName.next(userName);
    this.userEmail.next(userEmail);
    this.userClientName.next(clientName);
    this.userCompanyId.next(clientId);
  }

  getUsers(): Observable<ResponseModel<User[]>> {
    return this.http
      .get<ResponseModel<User[]>>(`${environment.API_URL}/users/lookup`)
      .pipe(catchError((err) => this.handleError(err)));
  }

  getMe(): Observable<ResponseModel<any>> {
    return this.http
      .get<ResponseModel<User[]>>(`${environment.API_URL}/users/me`)
      .pipe(catchError((err) => this.handleError(err)));
  }

  getPartnerAttributes(): Observable<ResponseModel<any[]>> {
    return this.http
      .get<ResponseModel<any[]>>(
        `${environment.API_URL}/partnerAttributes/detail`
      )
      .pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error ocurred retrieving data';
    if (err) {
      errorMessage = `Error: ${err.error.Message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
