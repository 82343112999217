<app-header (toggleSidenav)="sidenav.toggle()" (hideSidenav)="sidenav.close()"></app-header>
<mat-sidenav-container autosize id="mat-cont">
  <mat-sidenav #sidenav mode="side" style="height: 99%;">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>
