import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
  User,
  UserCredentials,
  UserProfile,
} from '@shared/models/user.interface';
import { Access } from '@shared/models/login.interface';
import { ResponseModel } from '@shared/models/response.interface';
import { NavItem } from '@shared/models/menu.inteface';

const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.checkToken();
  }

  get isLogged$(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  get permisions(): any {
    let permisions = localStorage.getItem('permisions');

    return permisions != null ? JSON.parse(permisions) : null;
  }

  login(authData: UserCredentials): Observable<ResponseModel<Access> | void> {
    return this.http
      .post<ResponseModel<Access>>(
        `${environment.API_URL}/auth/login`,
        authData
      )
      .pipe(
        tap((res: ResponseModel<Access>) => {
          if (res.Success) {
            this.saveToken(
              res.Data.Use2StepVerification ? 'temporaltoken' : 'token',
              res.Data.AccessToken
            );
            if (!res.Data.Use2StepVerification) this.loggedIn$.next(true);
          }
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  loginTwoStep(code: string): Observable<ResponseModel<Access> | void> {
    return this.http
      .post<ResponseModel<Access>>(`${environment.API_URL}/auth/login2step`, {
        Code: code,
      })
      .pipe(
        tap((res: ResponseModel<Access>) => {
          if (res.Success) {
            this.saveToken('token', res.Data.AccessToken);
            this.loggedIn$.next(true);
          }
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  logout(): Observable<ResponseModel<void> | void> {
    localStorage.removeItem('token');
    localStorage.removeItem('temporaltoken');
    localStorage.removeItem('permisions');
    this.loggedIn$.next(false);
    return this.http.post<ResponseModel<void>>(
      `${environment.API_URL}/auth/logout`,
      null
    );
  }

  forgotPassword(userName: string): Observable<ResponseModel<void> | void> {
    return this.http
      .post<ResponseModel<void>>(
        `${environment.API_URL}/auth/forgot-password`,
        { UserName: userName }
      )
      .pipe(catchError((err) => this.handleError(err)));
  }

  verifyCode(
    userName: string,
    code: string
  ): Observable<ResponseModel<Access> | void> {
    return this.http
      .post<ResponseModel<Access>>(`${environment.API_URL}/auth/verify-code`, {
        UserName: userName,
        Code: code,
      })
      .pipe(
        tap((res: ResponseModel<Access>) => {
          if (res.Success)
            this.saveToken('temporaltoken', res.Data.AccessToken);
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  restorePassword(
    newPassword: string,
    confirmPassword: string
  ): Observable<ResponseModel<void> | void> {
    return this.http
      .post<ResponseModel<void>>(
        `${environment.API_URL}/auth/restore-password`,
        {
          NewPassword: newPassword,
          ConfirmPassword: confirmPassword,
        }
      )
      .pipe(
        tap((res: ResponseModel<void>) => {
          localStorage.removeItem('temporaltoken');
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  readMenu(): Observable<ResponseModel<NavItem[]>> {
    return this.http
      .get<ResponseModel<NavItem[]>>(`${environment.API_URL}/auth/menu`)
      .pipe(
        tap((res) => {
          this.savePermisions(JSON.stringify(res.Data));
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  getProfile(): Observable<ResponseModel<UserProfile>> {
    return this.http
      .get<ResponseModel<UserProfile>>(`${environment.API_URL}/auth/profile`)
      .pipe(
        tap((res) => {
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  getMe(): Observable<ResponseModel<User>> {
    return this.http
      .get<ResponseModel<User>>(`${environment.API_URL}/users/me`)
      .pipe(
        tap((res) => {
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  register(registerData: User): Observable<ResponseModel<User> | void> {
    return this.http
      .post<ResponseModel<User>>(
        `${environment.API_URL}/auth/register`,
        registerData
      )
      .pipe(catchError((err) => this.handleError(err)));
  }

  private checkToken(): void {
    const userToken = localStorage.getItem('token');
    const isExpired = userToken ? helper.isTokenExpired(userToken) : true;
    isExpired ? this.logout() : this.loggedIn$.next(true);
  }

  private saveToken(key: string, token: string): void {
    localStorage.setItem(key, token);
  }

  private savePermisions(permisions: string): void {
    localStorage.setItem('permisions', permisions);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error ocurred retrieving data';
    if (err) {
      errorMessage = `Error: ${err.error.Message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
