import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { UserService } from './services/user.service';
import { locale, loadMessages } from "devextreme/localization";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'hersotek-portal';
  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private userService: UserService
  ) {
    loadMessages({
      "en": {
          "dxDataGrid-editingSaveRow": "Remove",
          "dxDataGrid-editingUnsaveRow": "Recover"
      }
    });
    locale('es-MX');
  }

  ngOnInit(): void {
    this.bnIdle
      .startWatching(environment.EXPIRATION_SESSION)
      .subscribe((res) => {
        if (res) {
          this.authSvc.logout().subscribe();
          this.router.navigate(['login']);
        }
      });
    
    this.screenHeight = window.screen.height;
    this.screenWidth = window.screen.width;
    console.log(this.screenWidth);
    console.log(this.screenHeight);
    let element = document.getElementById('mat-cont');
    if (environment.CustomerID != 'dobledigito') {
      if (this.screenWidth < 1600) {
        element?.classList.add('laptop-reorganiza');
      } else if (this.screenWidth > 1600) {
        element?.classList.add('monitor-reorganiza');
      }
    } else {
      if (this.screenWidth < 1600) {
        element?.classList.add('laptop-resolution');
      } else if (this.screenWidth > 1600) {
        element?.classList.add('monitor-resolution');
      }
    }
  }
}
