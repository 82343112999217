import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { MenuListItemComponent } from './shared/components/sidebar/menu-item/menu-list-item.component';
import { DialogConfirmationComponent } from '@shared/components/dialog-confirmation/dialog-confirmation.component';

import { MaterialModule } from '@app/material.module';

import { AddHeaderInterceptor } from '@core/add-header.interceptor';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InterceptorService } from './services/core/interceptor.service';

import { locale } from 'devextreme/localization';
import * as esMessages from 'devextreme/localization/messages/es.json';

import { loadMessages } from 'devextreme/localization';

// Carga los mensajes en español
loadMessages(esMessages);

// Establece el idioma en español
locale('es');

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MenuListItemComponent,
    DialogConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
