import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from '@app/shared/models/menu.inteface';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { MenuListItemComponent } from './menu-item/menu-list-item.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  menu!: NavItem[];
  subs!: Subscription;

  constructor(private authSvc: AuthService, public router: Router) {}

  ngOnInit(): void {
    this.subs = this.authSvc.isLogged$.subscribe((logged) => {
      if (logged) {
        this.authSvc.readMenu().subscribe((userMenu) => {
          this.menu = userMenu.Data;
          console.log(this.menu);
        });
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
