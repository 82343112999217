import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token')
      ? localStorage.getItem('token')
      : localStorage.getItem('temporaltoken');
    let jsonReque: HttpRequest<any>;
    if (token) {
      jsonReque = req.clone({
        setHeaders: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } else {
      jsonReque = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    }

    return next.handle(jsonReque);
  }
}
